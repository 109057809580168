import { useHistory } from 'react-router-dom';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import { isTemplateManagementTemplate } from 'components/template-management/utilities/isTemplate';
import Setup from 'components/data/Setup';
import User from 'components/data/User';

const useGoToTemplate = (): { goToTemplate: (template: TemplateAsset | null) => void; canGoToTemplate: (template: TemplateAsset | null) => boolean } => {
    const history = useHistory();

    // Check if this user can access the tmplate in Template Management.
    const canGoToTemplate = (template: TemplateAsset | null): template is TemplateAsset => {
        if (!template) return false;
        if (!Setup.hasModule('templateManagement')) return false;
        if (!isTemplateManagementTemplate(template)) return false;
        if (template.status !== 'available' && !(User.hasRight('assetManagerTemplateManagement') || User.hasRight('assetManagerTemplateWrite'))) return false;
        return true;
    };

    // Go to the template in Template Management.
    const goToTemplate = (template: TemplateAsset | null): void => {
        if (!canGoToTemplate(template)) return;
        const subPath = (() => {
            if (template.status === 'binned') return 'bin/';
            if (template.status === 'draft') return 'drafts/';
            return '';
        })();
        history.push(`/templates/${subPath}${template._id}`);
    };

    return { goToTemplate, canGoToTemplate };
};

export default useGoToTemplate;
