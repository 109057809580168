import Setup from 'components/data/Setup';
import User from 'components/data/User';

const canExportCreatives = (): boolean => {
    if (!Setup.hasModule('creativeManagement')) return false;
    if (!User.hasRight(['assetManagerCreativeManagement', 'assetManagerCreativeWrite'])) return false;
    return true;
};

export { canExportCreatives };
