import React from 'react';
import { AssetV2 } from 'types/asset.type';
import { getEditedCreativeAsset, isTemplateDesignerCreative } from 'components/creative-management-v2/utilities';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import AssetManagementVersionReleaseComparisonDialogConfirmNoCompare from 'components/asset-management/components/version-release-comparison-dialog/components/confirm-no-compare';
import useEditorChanged from 'components/editor-data/EditorData/hooks/useEditorChanged';
import CreativeManagementVersionReleaseComparisonDialog from '.';

interface Props {
    latestCreative: CreativeAsset;
    onSetFreshAsset?: (asset: AssetV2<unknown, unknown>) => void;
    onClose: () => void;
}

const CreativeManagementVersionReleaseComparisonDialogSwitch = ({ latestCreative, onSetFreshAsset, onClose }: Props) => {
    const changed = useEditorChanged();

    const latestCreativeWithChanges = (() => {
        if (changed) {
            return getEditedCreativeAsset(latestCreative);
        }
        return latestCreative;
    })();

    if (isTemplateDesignerCreative(latestCreativeWithChanges)) {
        return (
            <CreativeManagementVersionReleaseComparisonDialog latestCreative={latestCreativeWithChanges} onSetFreshAsset={onSetFreshAsset} onClose={onClose} />
        );
    }

    return (
        <AssetManagementVersionReleaseComparisonDialogConfirmNoCompare
            latestAsset={latestCreativeWithChanges}
            onSetFreshAsset={onSetFreshAsset}
            onClose={onClose}
        />
    );
};

export default CreativeManagementVersionReleaseComparisonDialogSwitch;
