import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ToolIconButton from 'components/ui-components/ToolIconButton';
import AMCheckbox from 'components/asset-management/components/am-checkbox';
import { useAMData, useAMNavigation } from 'components/asset-management/hooks/';
import { canOrganise } from 'components/asset-management/utilities';
import AssetManagementActionsWrapper from 'components/asset-management/components/actions/actions-wrapper';
import useAMAssetSelection from 'components/asset-management/hooks/useAMAssetSelection';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Icon from 'components/ui-components-v2/Icon';
import { CreativeAsset } from '../../../types/creative-management.type';
import CreativeManagementAssetMenu from '../../asset-menu';
import CreativeManagementAssetTileInfo from './info';
import CreativeManagementAssetTilePreview from './preview';

import '../styles/main.scss';
import StopPropagationWrapper from 'components/asset-management/components/stop-propagation-wrapper';
import AssetManagementReleaseIconButton from 'components/asset-management/components/actions/release-icon-button';
import { CreativeManagementVersionReleaseComparisonDialogSwitch } from '../../version-release-comparison-dialog';

interface Props {
    asset: CreativeAsset;
}

const CreativeManagementAssetTile = ({ asset }: Props) => {
    const { openAssetDetailOverlay } = useAMNavigation();
    const { userHasRight, subtypeIcons } = useAssetManagementConfigContext();
    const {
        data: { selectedAssetIds }
    } = useAMData<{ selectedAssetIds: string[] }>({
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const [hovered, setHovered] = useState(false);
    const [keepMenuMounted, setKeepMenuMounted] = useState(false);
    const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
    const canOrg = useMemo(() => canOrganise(userHasRight, asset.status), [asset.status]);
    const tileRef = useRef<HTMLDivElement>(null);

    const { isSelected, selectAsset } = useAMAssetSelection(asset._id);

    // We should the menu mounted when it's open or a dialog is opened from the menu, even if the mouse leaves the tile.
    const handleKeepMenuMounted = (open: boolean) => {
        setKeepMenuMounted(open);

        // When we close the menu, we want to set the hovered state to false because onMouseLeave isn't called in that scenario.
        if (!open) {
            if (!tileRef.current?.matches(':hover')) {
                setHovered(false);
            }
        }
    };

    const onTileClick = () => {
        if (selectedAssetIds.length) {
            selectAsset(asset._id);
            return;
        }

        openAssetDetailOverlay(asset._id);
    };

    return (
        <React.Fragment>
            <div
                id={`asset_${asset._id}`}
                data-tour-selector="asset-library-item"
                className={classNames('creative-management-asset-tile', 'asset-management-selectable-asset', {
                    'creatives-management-asset-tile--selected': isSelected
                })}
                ref={tileRef}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={onTileClick}>
                <div
                    className={classNames('creative-management-asset-tile__preview', {
                        'creative-management-asset-tile__preview--selected': isSelected
                    })}>
                    <CreativeManagementAssetTilePreview creative={asset} />
                    {(hovered || isSelected || keepMenuMounted) && (
                        <div className="creative-management-asset-tile__overlay">
                            <AMCheckbox asset={asset} className={'creative-management-asset-tile__checkbox'} />
                            {(hovered || keepMenuMounted) && (
                                <React.Fragment>
                                    <AssetManagementActionsWrapper className="creative-management-asset-tile__tools">
                                        <Tooltip title={Translation.get('actions.openPreview', 'asset-management')}>
                                            <span>
                                                <ToolIconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openAssetDetailOverlay(asset._id);
                                                    }}>
                                                    <Icon>open_in_full</Icon>
                                                </ToolIconButton>
                                            </span>
                                        </Tooltip>
                                        {canOrg && (
                                            <Tooltip title={Translation.get('actions.edit', 'creative-management-v2')}>
                                                <span>
                                                    <ToolIconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openAssetDetailOverlay(asset._id, 'edit');
                                                        }}>
                                                        <Icon>edit</Icon>
                                                    </ToolIconButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <CreativeManagementAssetMenu asset={asset} buttonType="toolIcon" onSetKeepMenuMounted={handleKeepMenuMounted} />
                                    </AssetManagementActionsWrapper>
                                    <div className="creative-management-asset-tile__label">{Translation.get('asset', 'creative-management-v2')}</div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
                <CreativeManagementAssetTileInfo
                    title={asset.title}
                    subtitle={Translation.get(`sub_types.${asset.subType}`, 'creative-management-v2')}
                    icon={subtypeIcons[asset.subType]}>
                    <StopPropagationWrapper>
                        <AssetManagementReleaseIconButton asset={asset} onSetReleaseDialogOpen={() => setReleaseDialogOpen(true)} />
                    </StopPropagationWrapper>
                </CreativeManagementAssetTileInfo>
            </div>
            {releaseDialogOpen && (
                <StopPropagationWrapper>
                    <CreativeManagementVersionReleaseComparisonDialogSwitch latestCreative={asset} onClose={() => setReleaseDialogOpen(false)} />
                </StopPropagationWrapper>
            )}
        </React.Fragment>
    );
};

export default CreativeManagementAssetTile;
