import { useEffect, useState } from 'react';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import Templates from 'components/data/Templates';
import { fetchTemplates } from 'components/template-management/utilities';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { CreativeSubType } from '../types/creative-sub-type.type';
import { isTemplateCreativeType, isCodedCreativeType } from '../utilities';

// Custom hook to fetch a template based on the template identifier and creative sub type.
const useTemplate = (
    templateIdentifier?: string | null,
    creativeSubType?: CreativeSubType,
    released?: boolean
): {
    loadingTemplate: boolean;
    template: TemplateAsset | null;
} => {
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const [template, setTemplate] = useState<TemplateAsset | null>(null);

    useEffect(() => {
        setLoadingTemplate(true);
        getTemplate(templateIdentifier, creativeSubType, released);
    }, [templateIdentifier, creativeSubType, released]);

    const getTemplate = async (templateIdentifier?: string | null, creativeSubType?: CreativeSubType, released?: boolean): Promise<void> => {
        setTemplate(null);
        if (!templateIdentifier) return setLoadingTemplate(false);
        if (!creativeSubType) return setLoadingTemplate(false);

        if (isCodedCreativeType(creativeSubType)) {
            const subType = (() => {
                if (isTemplateCreativeType(creativeSubType)) return creativeSubType;
                if (creativeSubType === 'socialChannelItem') return 'socialChannelItem';
                return 'staticAsset';
            })();
            const template = Templates.get(subType, templateIdentifier);
            setTemplate(template);
            setLoadingTemplate(false);
            return;
        }
        const templates = await fetchTemplates([templateIdentifier], released);
        if (templates?.length) setTemplate(templates[0]);
        setLoadingTemplate(false);
        if (templates && templates.length > 1) {
            // eslint-disable-next-line no-console
            SnackbarUtils.warning(Translation.get('snackbar.moreThenOneTemplate', 'creative-management-v2'));
        }
    };

    return { loadingTemplate, template };
};

export default useTemplate;
