import EditorData from 'components/editor-data/EditorData';
import cloneDeep from 'helpers/cloneDeep';
import { EditCreativeUuidHelpers } from '../helpers/edit-creative-uuid-helpers';
import { CreativeAsset } from '../types/creative-management.type';
import { isCreativeBuilderCreative, isVisualStackCreative } from './isCreative';
import { convertCreativeV1ToV2, getCreativeDataModel } from '.';

const getEditedCreativeAsset = (asset: CreativeAsset): CreativeAsset => {
    const uuid = EditCreativeUuidHelpers.getCreativeUuid();
    if (uuid) {
        const dataModel = getCreativeDataModel(uuid);
        const editorData = cloneDeep(EditorData.getValueFromModel(dataModel));
        if (editorData) {
            if (isCreativeBuilderCreative(asset)) {
                const convertedCreative = convertCreativeV1ToV2(editorData);
                const { data } = convertedCreative;

                if (data) return { ...asset, data };
            }
            if (isVisualStackCreative(asset)) {
                const data = { ...asset.data, templateInput: editorData };
                if (data) {
                    return { ...asset, data };
                }
            }
        }
    }
    return asset;
};

export { getEditedCreativeAsset };
