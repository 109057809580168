import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { merge, isEqual } from 'lodash';
import { AssetV2 } from 'types/asset.type';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import {
    FormatOptions,
    SubSetOptions
} from 'components/asset-management/components/version-release-comparison-dialog/types/asset-management-version-comparison-options.type';
import AssetManagementVersionReleaseComparisonDialog from 'components/asset-management/components/version-release-comparison-dialog';
import { TemplateCreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import { CreativeV2Template } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import Translation from 'components/data/Translation';
import { getAvailabeFormatsFromCreative, getTemplateIdentifierFromCreative } from 'components/creative-management-v2/utilities';
import { useTemplate } from 'components/creative-management-v2/hooks';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';

interface Props {
    latestCreative: TemplateCreativeAsset;
    onSetFreshAsset?: (asset: AssetV2<unknown, unknown>) => void;
    onClose: () => void;
}

const CreativeManagementVersionReleaseComparisonDialog = ({ latestCreative, onSetFreshAsset, onClose }: Props) => {
    const [releasedLoaded, setReleasedLoaded] = useState(false);
    const [releasedCreative, setReleasedCreative] = useState<TemplateCreativeAsset | null>(null);
    const [firstOptions, setFirstOptions] = useState<SubSetOptions | null>(null);
    const [activeFirstOptionsKey, setActiveFirstOptionsKey] = useState<string | null>(null);
    const [formatOptions, setFormatOptions] = useState<FormatOptions | null>(null);
    const [activeFormatKey, setActiveFormatKey] = useState<string | null>(null);
    const templateIdentifier = getTemplateIdentifierFromCreative(latestCreative);
    const { loadingTemplate, template } = useTemplate(templateIdentifier, latestCreative.subType, true);

    useEffect(() => {
        const getRealeasedCreative = () => {
            AssetManagementService.getAsset(latestCreative._id, { released: true })
                .then((response) => {
                    const typedReleasedCreative = response as TemplateCreativeAsset;
                    setReleasedCreative(typedReleasedCreative);
                    setReleasedLoaded(true);
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    setReleasedLoaded(true);
                });
        };
        getRealeasedCreative();
    }, []);

    useEffect(() => {
        const setOptions = async (releasedCreative: TemplateCreativeAsset) => {
            const formatOptions = await getOptions(latestCreative, releasedCreative);
            const activeFormatKey = Object.keys(formatOptions)[0];
            setFormatOptions(formatOptions);
            setActiveFormatKey(activeFormatKey);
            setFirstOptions({ none: { key: 'none', title: 'dummy', optionType: 'subSet', released: true, latest: true } });
            setActiveFirstOptionsKey('none');
        };

        if (releasedCreative) setOptions(releasedCreative);
    }, [releasedCreative]);

    const getOptions = async (latestCreative: TemplateCreativeAsset, releasedCreative?: TemplateCreativeAsset) => {
        const formatOptions: FormatOptions = {};

        const latestFormats = await getAvailabeFormatsFromCreative(latestCreative);
        const releasedFormats = releasedCreative && (await getAvailabeFormatsFromCreative(releasedCreative));
        const latestActiveFormats = latestCreative.data.settings.activeFormats;
        const releasedActiveFormats = releasedCreative && releasedCreative.data.settings.activeFormats;

        // Get the format options from the latest creative
        if (latestActiveFormats) {
            latestActiveFormats.forEach((formatKey) => {
                const thisFormat = latestFormats.find((format) => format.key === formatKey);
                if (thisFormat) formatOptions[formatKey] = { ...thisFormat, optionType: 'format', latest: true };
            });
        }

        // Add the format options from the released creative
        if (releasedActiveFormats && releasedFormats) {
            releasedActiveFormats.forEach((formatKey) => {
                if (formatOptions[formatKey]) {
                    formatOptions[formatKey].released = true;
                    const changed = isFormatChanged(latestCreative.data.templateInput, releasedCreative.data.templateInput, formatKey);
                    if (changed) formatOptions[formatKey].changed = true;
                } else {
                    const thisFormat = releasedFormats.find((format) => format.key === formatKey);
                    if (thisFormat) formatOptions[formatKey] = { ...thisFormat, optionType: 'format', released: true };
                }
            });
        }

        // Sort the options with new formats first, then the options that are in both versions, and then the options that are only in the released version.
        const sortedFormatOptions = Object.values(formatOptions)
            .sort((a) => {
                if (a.latest && !a.released) return -1;
                if (a.latest && a.released) return 0;
                if (!a.latest && a.released) return 1;
                return 0;
            })
            .reduce((acc, format) => {
                acc[format.key] = format;
                return acc;
            }, {} as FormatOptions);

        return sortedFormatOptions;
    };

    const isFormatChanged = (latestTemplateInput, releasedTemplateInput, formatKey) => {
        const { overwrites: latestOverwrite, ...latestGeneral } = latestTemplateInput;
        const { overwrites: releasedOverwrite, ...releasedGeneral } = releasedTemplateInput;
        const latestContent = latestOverwrite?.[formatKey] ? merge({}, latestGeneral, latestOverwrite[formatKey]) : latestGeneral;
        const releasedContent = releasedOverwrite?.[formatKey] ? merge({}, releasedGeneral, releasedOverwrite[formatKey]) : releasedGeneral;
        return !isEqual(latestContent, releasedContent);
    };

    const latestCreativeDisplay: CreativeV2Template | null = useMemo(() => {
        if (loadingTemplate) return null;
        if (!latestCreative) return null;
        if (!activeFirstOptionsKey) return null;
        if (!activeFormatKey) return null;
        if (!template) return null;
        TemplateManager.addTemplate(template);
        const templateType = template.subType;
        return {
            id: 'latestCreative',
            type: 'template',
            title: Translation.get('labels.latestCreative', 'creative-management-v2'),
            assetManagerId: uuidv4(),
            data: {
                templateType,
                templateIdentifier: latestCreative.data.templateIdentifier,
                settings: {
                    frames: 1,
                    activeFormats: [activeFormatKey]
                },
                templateInput: latestCreative.data.templateInput as any
            }
        };
    }, [latestCreative, templateIdentifier, activeFirstOptionsKey, activeFormatKey, loadingTemplate]);

    const releasedCreativeDisplay: CreativeV2Template | null = useMemo(() => {
        if (loadingTemplate) return null;
        if (!releasedCreative) return null;
        if (!activeFirstOptionsKey) return null;
        if (!activeFormatKey) return null;
        const templateType = template?.subType;
        if (!templateType) return null;
        return {
            id: 'releasedCreative',
            type: 'template',
            title: Translation.get('labels.releasedCreative', 'creative-management-v2'),
            assetManagerId: uuidv4(),
            data: {
                templateType,
                templateIdentifier: releasedCreative.data.templateIdentifier,
                settings: {
                    frames: 1,
                    activeFormats: [activeFormatKey]
                },
                templateInput: releasedCreative.data.templateInput as any
            }
        };
    }, [templateIdentifier, releasedCreative, activeFirstOptionsKey, activeFormatKey, loadingTemplate]);

    return (
        <AssetManagementVersionReleaseComparisonDialog
            latestAsset={latestCreative}
            releasedAsset={releasedCreative}
            latestCreative={latestCreativeDisplay}
            releasedCreative={releasedCreativeDisplay}
            loaded={releasedLoaded && !loadingTemplate}
            firstOptions={firstOptions}
            activeFirstOptionsKey={activeFirstOptionsKey}
            hideFirstOptions
            formatOptions={formatOptions}
            activeFormatKey={activeFormatKey}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            handleFrameTypeKeyChange={() => {}}
            handleFormatKeyChange={(key) => setActiveFormatKey(key)}
            onSetFreshAsset={onSetFreshAsset}
            onClose={onClose}
        />
    );
};

export default CreativeManagementVersionReleaseComparisonDialog;
