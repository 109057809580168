import React, { useEffect, useMemo, useState } from 'react';
import AssetManagementAssetMenuItemsSetAvailable from 'components/asset-management/components/asset-menu-items/set-available';
import AssetManagementAssetMenuItemsSetToDraft from 'components/asset-management/components/asset-menu-items/set-to-draft';
import AssetManagementAssetMenuItemsRestoreToDraft from 'components/asset-management/components/asset-menu-items/restore-to-draft';
import AssetManagementAssetMenuItemsMoveToBin from 'components/asset-management/components/asset-menu-items/move-to-bin';
import AssetManagementAssetMenuItemsDeletePermanently from 'components/asset-management/components/asset-menu-items/delete-permanently';
import AssetManagementAssetMenuItemsOrganise from 'components/asset-management/components/asset-menu-items/organise';
import AssetManagementAssetMenuItemsCopy from 'components/asset-management/components/asset-menu-items/copy';
import AssetManagementMenuItemsWrapper, { AssetManagerMenuButtonType } from 'components/asset-management/components/menu-items-wrapper';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { useAMNavigation } from 'components/asset-management/hooks';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import AssetManagementAssetMenuItemsRemoveFromCollection from 'components/asset-management/components/asset-menu-items/remove-from-collection';
import StopPropagationWrapper from 'components/asset-management/components/stop-propagation-wrapper';
import AssetManagementRemoveAssetDialog from 'components/asset-management/components/dialogs/remove-asset-dialog';
import AssetManagementSetToDraftDialog from 'components/asset-management/components/dialogs/set-to-draft-dialog';
import AssetManagementAssetMenuItemsRelease from 'components/asset-management/components/asset-menu-items/release';
import { CreativeManagementVersionReleaseComparisonDialogSwitch } from '../version-release-comparison-dialog';

interface Props {
    asset: CreativeAsset;
    buttonType?: AssetManagerMenuButtonType;
    onSetKeepMenuMounted?: (open: boolean) => void; // Used in case we want a callback to the parent component when the menu or dialogs are opened or closed.
}

const CreativeManagementAssetMenu = ({ asset, buttonType, onSetKeepMenuMounted }: Props) => {
    const { userHasRight } = useAssetManagementConfigContext();
    const { statusInView } = useAMNavigation();
    const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
    const [toDraftDialogOpen, setToDraftDialogOpen] = useState<boolean>(false);
    const [releaseDialogOpen, setReleaseDialogOpen] = useState<boolean>(false);
    const [permanent, setPermanent] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    // We only show the menu if any of the menu items would render given the status in the current view and the user rights.
    const showMenu = useMemo(() => {
        if (!userHasRight('write')) return false;
        if (statusInView === 'available' && !userHasRight('management')) return false;
        return true;
    }, [userHasRight, statusInView]);

    useEffect(() => {
        if (Boolean(anchorEl) || removeDialogOpen || toDraftDialogOpen || releaseDialogOpen) {
            if (onSetKeepMenuMounted) onSetKeepMenuMounted(true);
        } else {
            if (onSetKeepMenuMounted) onSetKeepMenuMounted(false);
        }
    }, [anchorEl, removeDialogOpen, toDraftDialogOpen, releaseDialogOpen]);

    const onSetAssetToRemove = (permanent: boolean) => {
        setRemoveDialogOpen(true);
        if (userHasRight('management')) setPermanent(permanent);
    };

    const onCloseRemove = () => {
        setRemoveDialogOpen(false);
        setPermanent(false);
    };

    if (!showMenu) return null;

    return (
        <StopPropagationWrapper>
            <AssetManagementMenuItemsWrapper buttonType={buttonType} open={Boolean(anchorEl)} anchorEl={anchorEl} waiting={waiting} onSetAnchorEl={setAnchorEl}>
                <AssetManagementAssetMenuItemsOrganise asset={asset} onClose={() => setAnchorEl(undefined)} />
                <AssetManagementAssetMenuItemsCopy asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsRelease asset={asset} onClose={() => setAnchorEl(undefined)} onSetReleaseDialogOpen={setReleaseDialogOpen} />
                <AssetManagementAssetMenuItemsSetAvailable asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsSetToDraft asset={asset} onClose={() => setAnchorEl(undefined)} onSetToDraftDialogOpen={setToDraftDialogOpen} />
                <AssetManagementAssetMenuItemsRestoreToDraft asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsRemoveFromCollection asset={asset} onClose={() => setAnchorEl(undefined)} onSetWaiting={setWaiting} />
                <AssetManagementAssetMenuItemsMoveToBin asset={asset} onClose={() => setAnchorEl(undefined)} onSetAssetToRemove={onSetAssetToRemove} />
                <AssetManagementAssetMenuItemsDeletePermanently asset={asset} onClose={() => setAnchorEl(undefined)} onSetAssetToRemove={onSetAssetToRemove} />
            </AssetManagementMenuItemsWrapper>
            {removeDialogOpen && <AssetManagementRemoveAssetDialog asset={asset} permanent={permanent} onClose={onCloseRemove} />}
            {toDraftDialogOpen && (
                <AssetManagementSetToDraftDialog
                    asset={asset}
                    onClose={() => setAnchorEl(undefined)}
                    onSetWaiting={setWaiting}
                    onSetDialogOpen={setToDraftDialogOpen}
                />
            )}
            {releaseDialogOpen && (
                <CreativeManagementVersionReleaseComparisonDialogSwitch
                    latestCreative={asset}
                    onClose={() => {
                        setReleaseDialogOpen(false);
                        setAnchorEl(undefined);
                    }}
                />
            )}
        </StopPropagationWrapper>
    );
};

export default CreativeManagementAssetMenu;
