import { v4 as uuidv4 } from 'uuid';
import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import Translation from 'components/data/Translation';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { CREATIVE_MANAGEMENT_CONFIG } from 'components/creative-management-v2/constants';
import { resetList } from 'components/asset-management/utilities';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { VisualStackCreativeSubType } from '../types/creative-sub-type.type';
import { CreativeAssetToCreate, VisualStackCreativeData } from '../types/creative-management.type';
import { canExportCreatives } from '.';

const exportVisualStackBlockToCreativeManagement = async (
    subType: VisualStackCreativeSubType,
    dataModel: string,
    editingItem: {
        uuid: string;
        identifier: string;
        title: string;
    },
    subsets?: boolean,
    groupKey?: string
): Promise<void> => {
    if (!canExportCreatives()) return;
    const newUuid = 'block' + uuidv4().replace(/-/g, '');
    const blockData = EditorData.getValueFromModel(dataModel + '.' + editingItem.uuid);
    const template = await Templates.asyncGetTemplatesItem(subType, editingItem.identifier);
    const newBockData = (() => {
        if (subsets) {
            return blockData.subsetData.main;
        } else {
            return blockData;
        }
    })();
    const data: VisualStackCreativeData = {
        templateInput: {
            setup: [
                {
                    uuid: newUuid,
                    identifier: editingItem.identifier,
                    type: subType
                }
            ],
            blocks: {
                [newUuid]: newBockData
            }
        },
        templateIdentifier: editingItem.identifier,
        groupKey,
        icon: template?.icon,
        image: template?.image
    };

    const newCreative: CreativeAssetToCreate = {
        data,
        title: `${editingItem.title} - imported`,
        type: 'creative',
        subType,
        status: 'draft'
    };
    const response = await AssetManagementService.createAsset(newCreative);
    if (response) {
        if (ComponentStoreHelpers.get(CREATIVE_MANAGEMENT_CONFIG.storeName)) {
            // Reset the drafts list in Creative Management.
            resetList(CREATIVE_MANAGEMENT_CONFIG.storeName, 'draft.');
        }
        SnackbarUtils.success(Translation.get('snackbar.exportedToCreatives', 'creative-management-v2'));
    }
};

export { exportVisualStackBlockToCreativeManagement };
